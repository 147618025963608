import { localStorageUtil } from "./storage";
import { store } from "@redux/store";
import { loginExpired } from "@redux/actions/authActions";
import Semaphore from "./semaphore";

const throttler = new Semaphore(1);

// Global variable to hold the token refresh promise
let refreshTokenPromise = null;

// Use direct fetch for normal requests
export async function fetcherNew1819(
    path,
    method = "GET",
    body,
    sendMultiPart = false,
    signal,
    isDownload = false,
    overrideToken = null
) {
    const api_endpoint = process.env.API_NEW_1819_URL || "https://prod.1819.is/api/v1/";
    console.log("api_endpoint", api_endpoint);

    let accessToken = overrideToken || localStorageUtil.getItem("access_token");
    const headers = {
        accept: "application/json",
        "app-key": process.env.API_NEW_1819_APP_KEY,
    };

    if (!sendMultiPart) {
        headers["Content-Type"] = "application/json";
    }

    if (accessToken) {
        headers.authorization = `Bearer ${accessToken}`;
    }

    if (body && !sendMultiPart) {
        body = JSON.stringify(body);
    }

    let options = {
        method,
        headers,
        body,
        signal,
        credentials: "include",
    };

    // Run the fetch request directly for concurrency
    const result = await fetch(api_endpoint + path, options);
    if (result.ok) {
        try {
            return isDownload ? await result.blob() : await result.json();
        } catch (e) {
            return null;
        }
    } else if (result.status === 498) {
        await handleTokenRefresh();
        // Introduce a short delay if needed
        await new Promise((resolve) => setTimeout(resolve, 100));
        const newToken = localStorageUtil.getItem("access_token");
        if (body && !sendMultiPart && typeof body === "string") {
            body = JSON.parse(body);
        }
        return await fetcherNew1819(
            path,
            method,
            body,
            sendMultiPart,
            signal,
            isDownload,
            newToken
        );
    } else if (result.status === 401) {
        localStorageUtil.removeItem("access_token");
        localStorageUtil.removeItem("user");
        store.dispatch(loginExpired());
    } else if (result.status === 403) {
        return await handleTokenRefresh();
    } else {
        try {
            const errorString = JSON.stringify(await result.json());
            throw { statusCode: result.status, message: errorString };
        } catch (e) {
            const errorString = e.message || "Unable to reach the server";
            throw { statusCode: result.status, message: errorString };
        }
    }
}

async function handleTokenRefresh() {
    const api_endpoint = process.env.API_NEW_1819_URL || "https://prod.1819.is/api/v1/";
    if (refreshTokenPromise) {
        return refreshTokenPromise;
    }
    refreshTokenPromise = (async () => {
        const accessToken = localStorageUtil.getItem("access_token");
        const headers = {
            accept: "application/json",
            "content-type": "application/json",
            "app-key": process.env.API_NEW_1819_APP_KEY,
        };

        if (accessToken) {
            headers.authorization = `Bearer ${accessToken}`;
        }

        let options = {
            method: "POST",
            headers: headers,
            credentials: "include",
        };
        try {
            let tokensResponse = await throttler.callFunction(
                fetch,
                `${api_endpoint}token`,
                options
            );
            if (tokensResponse.status === 401) {
                localStorageUtil.removeItem("access_token");
                localStorageUtil.removeItem("user");
                store.dispatch(loginExpired());
                throw new Error("Session expired");
            }
            if (!tokensResponse.ok) {
                throw new Error("Failed to fetch new token");
            }
            let tokens = await tokensResponse.json();
            localStorageUtil.setItem("access_token", tokens.access_token);
            return tokens.access_token;
        } catch (e) {
            console.error("Error fetching new token:", e);
            throw e;
        } finally {
            refreshTokenPromise = null;
        }
    })();
    return refreshTokenPromise;
}
