import { faList, faQuestion, faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { THJONUSTUVER_QUEUE_NUMBER } from "@utils/constants";
import { fetcherNew1819 } from "@utils/fetcherNew1819";
import { phoneSystemFetcher } from "@utils/phoneSystemFetcher";

export const types = {
    get_phone_info: "get_phone_info",
    delete_phone_info: "delete_phone_info",
    set_selected_phone: "set_selected_phone",
    clear_phone_info: "clear_phone_info",
    set_phone_info: "set_phone_info",
    clear_current_phone: "clear_current_phone",
    set_status: "set_status",
    set_number_to_call: "set_number_to_call",
    clear_number_to_call: "clear_number_to_call",
    make_call: "make_call",
    has_incoming_call: "has_incoming_call",
    has_outgoing_call: "has_outgoing_call",
    set_caller_info: "set_caller_info",
    clear_calls: "clear_calls",
    call_connected: "call_connected",
    set_parked_calls: "set_parked_calls",
    set_attendant_status: "set_attendant_status",
    set_script: "set_script",
    clear_script: "clear_script",
    add_status: "add_status",
    toggle_email_modal: "toggle_email_modal",
    toggle_auto_answer: "toggle_auto_answer",
    set_my_calls: "set_my_calls",
    set_waiting: "set_waiting",
    set_auto_answer_timer_active: "set_auto_answer_timer_active",
    set_send_sms: "set_send_sms",
    set_is_playing_audio: "set_is_playing_audio",
    set_call_id: "set_call_id",
    set_email_modal_info: "set_email_modal_info",
    set_email_fields: "set_email_fields",
    clear_email_modal_info: "clear_email_modal_info",
    open_today_call_history_modal: "open_today_call_history_modal",
    close_today_call_history_modal: "close_today_call_history_modal",
    set_selected_call_log: "set_selected_call_log",
    open_comment_modal: "open_comment_modal",
    close_comment_modal: "close_comment_modal",
    set_comment_type: "set_comment_type",
    open_statistics_buttons_modal: "open_statistics_buttons_modal",
    close_statistics_buttons_modal: "close_statistics_buttons_modal",
    set_statistics_buttons: "set_statistics_buttons",
    set_waiting_statistics_buttons: "set_waiting_statistics_buttons",
    set_company_id: "set_company_id",
    set_waiting_company_id: "set_waiting_company_id",
    set_3cx_call_id: "set_3cx_call_id",
    set_is_fetching_script: "set_is_fetching_script",
    set_use_ps_proxy: "set_use_ps_proxy",
};

export const addStatus = (status) => (dispatch) => {
    dispatch({
        type: types.add_status,
        payload: { status: { date: new Date(), color: "black", ...status } },
    });
};

export const getPhoneInfo = (phone, compid) => async (dispatch, getState) => {
    if (!phone) {
        phone = getState()?.phoneSystem?.transfer_to_phone;
    }
    if (!compid) {
        compid = getState()?.phoneSystem?.transfer_to_company_id;
    }
    let url = `/mitt/registration/phone?phone=${phone}`;
    if (compid) url = url + `&id=${compid}`;

    try {
        const data = await fetcherNew1819(url);
        if (data?.name) {
            data.phone = phone;
            if (data.address == null || data.address == undefined || data.address == "") {
                data.address = "";
            }
            if (!data.zip == null || data.zip == undefined || data.zip == "") {
                data.zip = "";
            }
            data.address = [data.address.trim(), data.zip.trim()].join(", ");
            if (data.address == ", ") {
                data.address = "";
            }
            dispatch({
                type: types.get_phone_info,
                payload: data,
            });
        } else {
            dispatch({
                type: types.delete_phone_info,
            });
            return;
        }
    } catch (e) {
        dispatch({
            type: types.delete_phone_info,
        });
        dispatch({
            type: types.get_phone_info,
            payload: { phone: phone, name: "Þú varst áframsend/ur á" },
        });
        return;
    }
};

export const sendSMS = (phoneNumber) => async (dispatch, getState) => {
    const smsToSend = getState()?.phoneSystem?.sms_to_send;
    if (!smsToSend) {
        return;
    }
    const { name, address, phone } = smsToSend;
    if (!phone) return;
    if (!phoneNumber) {
        dispatch(
            addStatus({
                text: 'Númer vantar í "Símanr. viðtakanda"',
                color: "#cf0000",
            })
        );
        return;
    }

    let details = {
        recipient: phoneNumber.toString(),
        phone: phone,
        name: name,
        address: address || "",
    };
    let callID = getState()?.phoneSystem?.call_id;
    try {
        await fetcherNew1819("mitt/sms/thjonustuver", "POST", details);
        dispatch(
            addStatus({ text: `Upplýsingar um ${phone} sent á ${phoneNumber}.`, color: "green" })
        );
        dispatch(logSendSMS(phoneNumber, callID));
    } catch (e) {
        console.log(e);
        dispatch(
            addStatus({
                text: `Villa við að senda upplýsingar um ${phone} á ${phoneNumber}.`,
                color: "#cf0000",
            })
        );
    } finally {
        dispatch({ type: types.clear_phone_info });
        dispatch({ type: types.clear_current_phone });
    }
};

export const clearPhoneInfo = () => (dispatch) => {
    dispatch({
        type: types.clear_phone_info,
    });
};

export const setShouldSendSMS = (sendSMS) => (dispatch) => {
    dispatch({
        type: types.set_send_sms,
        payload: sendSMS,
    });
};

export const setIsPlayingAudio = (isPlaying) => (dispatch) => {
    dispatch({
        type: types.set_is_playing_audio,
        payload: isPlaying,
    });
};

export const setPhoneInfo = (sms_to_send) => (dispatch) => {
    dispatch({
        type: types.set_phone_info,
        payload: sms_to_send,
    });
};

let timeout = null;

export const setSelectedPhone = (phone, compid) => (dispatch) => {
    dispatch({
        type: types.set_selected_phone,
        payload: {
            transfer_to_phone: phone,
            transfer_to_company_id: compid,
        },
    });
    if (phone.length === 3 || phone.length === 4 || phone.length === 7) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            dispatch(getPhoneInfo());
        }, 300);
    }
};

export const setNumberToCall = (phone) => (dispatch) => {
    dispatch({
        type: types.set_number_to_call,
        payload: {
            number_to_call: phone,
        },
    });
};

export const setStatus = (status) => (dispatch) => {
    dispatch({
        type: types.set_status,
        payload: { status: status },
    });
};

// Phone System Connection
export const makeCall = () => async (dispatch, getState) => {
    let extension = getState()?.auth?.user?.extension_3cx;
    let numberToCall = getState()?.phoneSystem?.number_to_call;

    if (!numberToCall.startsWith("+") || !numberToCall.startsWith("00")) {
        numberToCall = "+354" + numberToCall;
    }
    if (numberToCall) {
        dispatch(
            addStatus({
                text: `Hringi í ${numberToCall}`,
                color: "green",
            })
        );

        dispatch(logOutboundCall());
        await phoneSystemFetcher("makecall", [extension, numberToCall, "Web"]);
        dispatch(getAllCalls());
        dispatch({
            type: types.make_call,
        });
    }
};

export const transferCall = (phone) => async (dispatch, getState) => {
    let extension = getState()?.auth?.user?.extension_3cx;
    if (!extension) {
        return;
    }
    if (phone.length === 3 || phone.length === 4 || phone.length === 7) {
        dispatch(logTransferCall(phone));
        let result = await phoneSystemFetcher("transfer", [extension, "+354" + phone]);
        if (result == "true" || result == true) {
            dispatch(dropCall());
            let callerPhone;
            if (getState()?.phoneSystem.has_outgoing_call) {
                callerPhone = getState()?.phoneSystem?.number_to_call;
            } else {
                callerPhone = getState()?.phoneSystem?.caller_information?.phone;
            }
            dispatch(addStatus({ text: `${callerPhone} áframsent á ${phone}`, color: "green" }));
            return;
        } else {
            dispatch(
                addStatus({
                    text: `Möguleg villa við áframsendingu á ${
                        getState()?.phoneSystem?.caller_information?.phone
                    } til ${phone}`,
                    color: "orange",
                })
            );
        }
    }
};

export const dropCall = () => async (dispatch, getState) => {
    let extension = getState()?.auth?.user?.extension_3cx;
    if (!extension) {
        return;
    }
    await phoneSystemFetcher("drop", [extension]);
    dispatch(getAllCalls());
};

export const getAllCalls = () => async (dispatch, getState) => {
    let extension = getState()?.auth?.user?.extension_3cx;
    if (!extension) {
        return;
    }
    let status = await phoneSystemFetcher("showallcalls", []);
    if (status == null || status == undefined) {
        return;
    }
    status = status.replace("<html><head></head><body>", "");
    status = status.replace("</body></html>", "");
    status = status.split("<br>");
    status = status.map((item) => {
        return item.split(";");
    });
    let allCalls = [];
    let waiting1819 = 0;
    let waitingOther = 0;
    status.forEach((item) => {
        if (item == "") {
            return;
        }
        let result = {};
        item.forEach((item2) => {
            let splitted = item2.split("=");
            return (result[splitted[0]] = splitted[1]);
        });
        if (result?.DN == THJONUSTUVER_QUEUE_NUMBER) {
            waiting1819++;
        } else if (result?.DN?.startsWith("8")) {
            waitingOther++;
        }
        allCalls.push(result);
    });
    let myCalls = allCalls.filter((call) => call.DN == extension);
    let currentCall = myCalls[0];

    dispatch({
        type: types.set_my_calls,
        payload: { my_calls: myCalls },
    });
    dispatch({
        type: types.set_waiting,
        payload: { waiting1819, waitingOther },
    });

    if (!currentCall) {
        dispatch(clearCalls());
        dispatch(clearScript());
        return;
    }

    if (currentCall.S == "Ringing") {
        dispatch(incomingCall(currentCall.EP, currentCall.Queue_Nummer, currentCall.Queue_Name));
        if (
            !getState()?.phoneSystem?.script &&
            currentCall.Queue_Nummer != THJONUSTUVER_QUEUE_NUMBER
        ) {
            dispatch(getScript(currentCall?.Queue_Nummer));
        }
    } else if (currentCall.S == "Dialing") {
        dispatch(outgoingCall(currentCall.EP));
    } else if (currentCall.S == "Connected") {
        dispatch(callConnected());
    }
};

export const log1819Call = () => async (dispatch, getState) => {
    const phoneSystem = getState()?.phoneSystem;
    const body = {
        phone: phoneSystem?.caller_information?.phone?.toString(),
        call_id_3cx: phoneSystem?.call_id_3cx,
    };
    try {
        const call = await fetcherNew1819("stats/call/1819", "POST", body);
        if (call?.id) {
            dispatch(setCallID(call.id));
        }
        dispatch(addStatus({ text: `1819 símtal skráð með ID ${call.id}`, color: "green" }));
    } catch (e) {
        dispatch(addStatus({ text: "Villa við að skrá símtal", color: "#cf0000" }));
    }
};

export const logExternalCall = () => async (dispatch, getState) => {
    const phoneSystem = getState()?.phoneSystem;
    const body = {
        call_3cx_id: phoneSystem?.call_id_3cx,
        phone: phoneSystem?.caller_information?.phone?.toString(),
        queue: parseInt(phoneSystem?.my_calls?.[0]?.Queue_Nummer),
        call_id_3cx: phoneSystem?.call_id_3cx,
    };
    try {
        const call = await fetcherNew1819("stats/call/external", "POST", body);
        if (call?.id) {
            dispatch(setCallID(call.id));
        }
        dispatch(addStatus({ text: `Símsvörunar símtal skráð með ID ${call.id}`, color: "green" }));
    } catch (e) {
        dispatch(addStatus({ text: "Villa við að skrá símsvörunarsímtal", color: "#cf0000" }));
    }
};

export const logOutboundCall = () => async (dispatch, getState) => {
    const phoneSystem = getState()?.phoneSystem;
    const body = {
        phone: phoneSystem?.number_to_call?.toString(),
        call_id_3cx: phoneSystem?.call_id_3cx,
    };
    try {
        const call = await fetcherNew1819("stats/call/outbound", "POST", body);
        if (call?.id) {
            dispatch(setCallID(call.id));
        }
        dispatch(addStatus({ text: `Útsímtal skráð með ID ${call.id}`, color: "green" }));
    } catch (e) {
        console.log(e);
        dispatch(addStatus({ text: "Villa við að skrá útsímtal", color: "#cf0000" }));
    }
};

export const logTransferCall = (transferPhone) => async (dispatch, getState) => {
    const phoneSystem = getState()?.phoneSystem;
    if (phoneSystem?.call_id == null) {
        dispatch(
            addStatus({
                text: "Ekkert Call ID. Vinsamlegast ekki svara í 3CXPhone.  TRANSFER.",
                color: "#cf0000",
            })
        );
    }
    let phone;
    if (phoneSystem.has_outgoing_call) {
        phone = phoneSystem?.number_to_call;
    } else {
        phone = phoneSystem?.caller_information?.phone;
    }

    const body = {
        phone: phone?.toString(),
        call_id: phoneSystem?.call_id,
        transfer_phone: transferPhone,
        call_id_3cx: phoneSystem?.call_id_3cx,
    };
    try {
        await fetcherNew1819("stats/call/transfer", "POST", body);
        dispatch(addStatus({ text: "Áframsending skráð", color: "green" }));
    } catch (e) {
        console.log(e);
        dispatch(addStatus({ text: "Villa við að skrá áframsendingu", color: "#cf0000" }));
    }
};

export const logSendSMS = (phone, callID) => async (dispatch, getState) => {
    const phoneSystem = getState()?.phoneSystem;
    if (!callID) {
        dispatch(
            addStatus({
                text: "Ekkert Call ID. Vinsamlegast ekki svara í 3CXPhone. SMS.",
                color: "#cf0000",
            })
        );
    }

    const body = {
        phone: phone?.toString(),
        call_id: callID,
        sms_phone: phoneSystem?.sms_to_send?.phone,
        call_id_3cx: phoneSystem?.call_id_3cx,
    };
    try {
        const call = await fetcherNew1819("stats/call/sms", "POST", body);
        dispatch(addStatus({ text: `SMS skráð með ID ${call.id}`, color: "green" }));
    } catch (e) {
        console.log(e);
        dispatch(addStatus({ text: "Villa við að skrá SMS sendingu", color: "#cf0000" }));
    }
};

export const logGaveInfoAboutNumber = () => async (dispatch, getState) => {
    const phoneSystem = getState()?.phoneSystem;
    if (phoneSystem?.call_id == null) {
        dispatch(
            addStatus({
                text: "Ekkert Call ID. Vinsamlegast ekki svara í 3CXPhone.  GAF.",
                color: "#cf0000",
            })
        );
    }
    const body = {
        phone: phoneSystem?.caller_information?.phone?.toString(),
        call_id: phoneSystem?.call_id,
        info_phone: phoneSystem?.sms_to_send?.phone,
        call_id_3cx: phoneSystem?.call_id_3cx,
    };
    try {
        await fetcherNew1819("stats/call/info", "POST", body);
        dispatch(
            addStatus({
                text: `Þú hefur veitt upplýsingar um ${phoneSystem?.sms_to_send?.phone}`,
                color: "green",
            })
        );
    } catch (e) {
        console.log(e);
        dispatch(addStatus({ text: "Villa við að skrá Gaf upplýsingar", color: "#cf0000" }));
    }
};

export const answerCall = () => async (dispatch, getState) => {
    dispatch(setStatisticsButtons(null));
    dispatch(setCompanyID(null));
    let extension = getState()?.auth?.user?.extension_3cx;
    if (!extension) {
        return;
    }
    dispatch({
        type: types.clear_phone_info,
    });
    dispatch({
        type: types.clear_current_phone,
    });
    if (
        getState()?.phoneSystem?.waiting_statistics_buttons &&
        getState()?.phoneSystem?.waiting_statistics_buttons?.length > 0
    ) {
        dispatch(openStatisticsButtonsModal());
        dispatch(setStatisticsButtons(getState()?.phoneSystem?.waiting_statistics_buttons));
        dispatch(setCompanyID(getState()?.phoneSystem?.waiting_company_id));
    }
    // ANSWER THE NEW CALL
    const callID = await phoneSystemFetcher("answer", [extension]);
    dispatch(set3cxCallID(callID));

    let queueNumber = getState()?.phoneSystem?.my_calls?.[0]?.Queue_Nummer;

    if (queueNumber == THJONUSTUVER_QUEUE_NUMBER) {
        dispatch(log1819Call());
    } else {
        dispatch(logExternalCall());
    }
    dispatch(getAllCalls());
};

export const setCallID = (id) => async (dispatch) => {
    dispatch({
        type: types.set_call_id,
        payload: { id },
    });
};

export const set3cxCallID = (id) => async (dispatch) => {
    dispatch({
        type: types.set_3cx_call_id,
        payload: id,
    });
};

export const logOut = () => async (dispatch, getState) => {
    let extension = getState()?.auth?.user?.extension_3cx;
    if (!extension) {
        return;
    }
    await phoneSystemFetcher("logout", [extension]);
    dispatch(addStatus({ text: "Aftengdist", color: "#cf0000" }));
    dispatch(getAttendantStatus());
};

export const setLunch = () => async (dispatch, getState) => {
    let extension = getState()?.auth?.user?.extension_3cx;
    if (!extension) {
        return;
    }
    await phoneSystemFetcher("notready", [extension]);
    dispatch(addStatus({ text: "Skráð(ur) í mat", color: "#FFA327" }));
    dispatch(getAttendantStatus());
};

export const getAttendantStatus = () => async (dispatch, getState) => {
    let extension = getState()?.auth?.user?.extension_3cx;
    if (!extension) {
        return;
    }
    let status = await phoneSystemFetcher("showstatus", [extension]);
    dispatch({
        type: types.set_attendant_status,
        payload: { attendantStatus: status },
    });
};

export const setStatusReady = () => async (dispatch, getState) => {
    let extension = getState()?.auth?.user?.extension_3cx;
    if (!extension) {
        return;
    }
    await phoneSystemFetcher("ready", [extension]);
    dispatch(addStatus({ text: "Tengdist", color: "green" }));
    dispatch(getAttendantStatus());
};

export const incomingCall =
    (fromPhoneNumber, queueNumber, queueName) => async (dispatch, getState) => {
        const strippedPhoneNumber = fromPhoneNumber?.replace?.("+354", "");
        dispatch({
            type: types.has_incoming_call,
            payload: { queue_name: queueName },
        });

        if (getState()?.phoneSystem?.caller_information) return;
        const callLog = {
            phone: strippedPhoneNumber,
            queueNumber: queueNumber,
            queueName: queueName,
        };
        dispatch(
            addStatus({
                separator: true,
                text: strippedPhoneNumber + " hringir í " + queueName,
                buttons: [
                    {
                        icon: faThumbsUp,
                        color: "#198754",
                        onClick: () => {
                            dispatch(openCommentModal());
                            dispatch(setSelectedCallLog(callLog));
                            dispatch(setCommentType("positive"));
                        },
                    },
                    {
                        icon: faThumbsDown,
                        color: "#cf0000",
                        onClick: () => {
                            dispatch(openCommentModal());
                            dispatch(setSelectedCallLog(callLog));
                            dispatch(setCommentType("negative"));
                        },
                    },
                    {
                        icon: faList,
                        onClick: () => {
                            dispatch(openTodayCallHistoryModal());
                            dispatch(setSelectedCallLog(callLog));
                        },
                    },
                ],
            })
        );

        let url = `/mitt/registration/phone?phone=${strippedPhoneNumber}`;
        try {
            const data = await fetcherNew1819(url);
            dispatch({
                type: types.set_caller_info,
                payload: { caller_information: data },
            });
            if (data?.name) {
                dispatch(addStatus({ text: data?.name }));
            }
        } catch (e) {
            console.log(e);
            dispatch({
                type: types.set_caller_info,
                payload: { caller_information: { phone: strippedPhoneNumber } },
            });
        }
    };

export const outgoingCall = (toPhoneNumber) => async (dispatch, getState) => {
    dispatch({
        type: types.has_outgoing_call,
    });
    if (getState()?.phoneSystem?.caller_information) return;

    let url = `/mitt/registration/phone?phone=${toPhoneNumber}`;
    try {
        const data = await fetcherNew1819(url);
        dispatch({
            type: types.set_caller_info,
            payload: { caller_information: data },
        });
    } catch (e) {
        console.log(e);
    }
};

export const clearCalls = () => (dispatch) => {
    dispatch({
        type: types.clear_calls,
    });
};

export const callConnected = () => (dispatch, getState) => {
    const callStartTime = getState()?.phoneSystem?.call_start_time;
    dispatch({
        type: types.call_connected,
        payload: {
            call_start_time: callStartTime ? callStartTime : new Date().toString(),
        },
    });
};

// export const setParkedCalls = (calls) => (dispatch) => {
//     dispatch({
//         type: types.set_parked_calls,
//         payload: { parked_calls: calls },
//     });
// };

// Script

export const getScript = (fromExtension) => async (dispatch, getState) => {
    if (!fromExtension || fromExtension == "null") {
        return;
    }
    try {
        const controller = new AbortController();

        dispatch({
            type: types.set_script,
            payload: {
                script_abort_controller: controller,
                queue_number: fromExtension,
            },
        });
        dispatch(setIsFetchingScript(true));
        let company = await fetcherNew1819(
            `mitt/answering/company?queue-ext=${fromExtension}`,
            "GET",
            null,
            false,
            controller.signal,
            false
        );
        dispatch(setIsFetchingScript(false));

        if (company == null) {
            return;
        }
        const shouldPlayAudio =
            company?.initial_audio_url != null &&
            company?.initial_audio_url != undefined &&
            !getState()?.phoneSystem?.is_playing_audio;

        if (shouldPlayAudio) {
            dispatch(setIsPlayingAudio(true));
            const audio = new Audio(company?.initial_audio_url);
            audio.play();
            audio.onended = () => {
                dispatch(setIsPlayingAudio(false));
            };
        }

        // IF the we are already in a call: set the statistics_buttons
        console.log("CHECKING IF IN CALL");

        if (getState()?.phoneSystem?.in_phone_call) {
            //Open the statistics buttons modal
            dispatch(setCompanyID(company?.id));

            if (company?.main_script) {
                dispatch(setShouldSendSMS(false));
            } else {
                dispatch(setShouldSendSMS(true));
            }

            if (company?.statistics_buttons?.length > 0) {
                dispatch(setStatisticsButtons(company?.statistics_buttons));
                dispatch(openStatisticsButtonsModal());
            }
        }

        dispatch({
            type: types.set_script,
            payload: {
                waiting_company_id: company?.id,
                queue_number: fromExtension,
                script: company?.main_script,
                audio: company?.initial_audio_url,
                script_email_fields: company?.email_fields,
                waiting_statistics_buttons: company?.statistics_buttons,
            },
        });
    } catch (e) {
        dispatch(setIsFetchingScript(false));
        console.log(e);
    }
};

export const clearScript = () => async (dispatch, getState) => {
    if (getState().phoneSystem.script) {
        getState()?.phoneSystem?.script_abort_controllers?.forEach((controller) =>
            controller?.abort()
        );

        dispatch({
            type: types.clear_script,
        });
    }
};

export const toggleEmailModal = () => async (dispatch) => {
    dispatch({
        type: types.toggle_email_modal,
    });
};

export const openStatisticsButtonsModal = () => async (dispatch) => {
    dispatch({
        type: types.open_statistics_buttons_modal,
    });
};

export const closeStatisticsButtonsModal = () => async (dispatch) => {
    dispatch({
        type: types.close_statistics_buttons_modal,
    });
};

export const setStatisticsButtons = (buttons) => async (dispatch) => {
    dispatch({
        type: types.set_statistics_buttons,
        payload: buttons,
    });
};

export const setCompanyID = (id) => async (dispatch) => {
    dispatch({
        type: types.set_company_id,
        payload: id,
    });
};

export const setEmailFields = () => async (dispatch, getState) => {
    const fields = getState()?.phoneSystem?.script_email_fields;
    if (!fields) return;
    dispatch({
        type: types.set_email_fields,
        payload: {
            email_fields: fields,
        },
    });
};

export const toggleAutoAnswer = () => async (dispatch) => {
    dispatch({
        type: types.toggle_auto_answer,
    });
};

export const setEmailModalInfo = (info) => async (dispatch) => {
    dispatch({
        type: types.set_email_modal_info,
        payload: info,
    });
};

export const clearEmailModalInfo = (info) => async (dispatch) => {
    dispatch({
        type: types.clear_email_modal_info,
        payload: info,
    });
};

export const setSelectedCallLog = (callLog) => async (dispatch) => {
    dispatch({
        type: types.set_selected_call_log,
        payload: callLog,
    });
};

export const openTodayCallHistoryModal = () => async (dispatch) => {
    dispatch({
        type: types.open_today_call_history_modal,
    });
};

export const closeTodayCallHistoryModal = () => async (dispatch) => {
    dispatch({
        type: types.close_today_call_history_modal,
    });
};

export const openCommentModal = () => async (dispatch) => {
    dispatch({
        type: types.open_comment_modal,
    });
};

export const closeCommentModal = () => async (dispatch) => {
    dispatch({
        type: types.close_comment_modal,
    });
};

export const setCommentType = (type) => async (dispatch) => {
    dispatch({
        type: types.set_comment_type,
        payload: type,
    });
};

export const setIsFetchingScript = (isFetching) => async (dispatch) => {
    dispatch({
        type: types.set_is_fetching_script,
        payload: isFetching,
    });
};

export const setUsePsProxy = (usePsProxy) => (dispatch) => {
    dispatch({
        type: types.set_use_ps_proxy,
        payload: usePsProxy,
    });
};
