import { PHONE_SYSTEM_API } from "./constants";
import { fetcherNew1819 } from "./fetcherNew1819";
import { store } from "@redux/store";

export async function phoneSystemFetcher(action, params) {
    const usePsProxy = store.getState()?.phoneSystem?.use_ps_proxy;

    if (usePsProxy) {
        const endpoint = `threecx/${action}/${params.join("/")}`;
        return await fetcherNew1819(endpoint);
    }

    const api_endpoint = PHONE_SYSTEM_API;
    const MAX_RETRIES = 3;
    let retryCount = 0;

    const options = {
        crossDomain: true,
        // Use AbortController for timeout
        signal: (() => {
            const controller = new AbortController();
            setTimeout(() => controller.abort(), 4000);
            return controller.signal;
        })(),
    };

    while (retryCount < MAX_RETRIES) {
        try {
            const result = await fetch(api_endpoint + action + "/" + params.join("/"), options);
            if (result.ok) {
                return await result.text();
            }
            throw new Error(`HTTP error! status: ${result.status}`);
        } catch (error) {
            retryCount++;
            if (retryCount === MAX_RETRIES) {
                throw error;
            }
            // Wait before retrying
            await new Promise((resolve) => setTimeout(resolve, 1000 * retryCount));
        }
    }
}
